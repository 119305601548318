<template>
  <div>
    <div class="exportTitle">
      <h3>商品导出</h3>
      <p class="clp">导出字段：</p>
      <el-checkbox-group v-model="checkList">
        <el-checkbox class="check" label="item_no">编码</el-checkbox>
        <el-checkbox class="check" label="b_type">大类别</el-checkbox>
        <el-checkbox class="check" label="b_type_en">大类别(en)</el-checkbox>
        <el-checkbox class="check" label="m_type">中类别</el-checkbox>
        <el-checkbox class="check" label="m_type_en">中类别(en)</el-checkbox>
        <el-checkbox class="check" label="gt_cls_no">小类别</el-checkbox>
        <el-checkbox class="check" label="gt_cls_no_en">小类别(en)</el-checkbox>
        <el-checkbox class="check" label="img_link">主图</el-checkbox>
        <el-checkbox class="check" label="item_name">名称</el-checkbox>
        <el-checkbox class="check" label="item_en">名称(EN)</el-checkbox>
        <el-checkbox class="check" label="lot_spec">箱规</el-checkbox>
        <el-checkbox class="check" label="base_price">配送价</el-checkbox>
        <el-checkbox class="check" label="sale_price">零售价</el-checkbox>
        <el-checkbox class="check" label="stock_num">库存数量</el-checkbox>
        <el-checkbox class="check" label="carts_num">购物车占用</el-checkbox>
        <el-checkbox class="check" label="order_num">订单占用</el-checkbox>
        <el-checkbox class="check" label="display_flag">是否上线</el-checkbox>
        <el-checkbox class="check" label="material">材质</el-checkbox>
        <el-checkbox class="check" label="material_en">材质(en)</el-checkbox>
        <el-checkbox class="check" label="lwh">尺寸</el-checkbox>
        <el-checkbox class="check" label="lwh_en">尺寸(en)</el-checkbox>
        <el-checkbox class="check" label="weight">重量</el-checkbox>
        <el-checkbox class="check" label="weight_en">重量(en)</el-checkbox>
        <el-checkbox class="check" label="stuffing">填充物</el-checkbox>
        <el-checkbox class="check" label="stuffing_en">填充物(en)</el-checkbox>
        <el-checkbox class="check" label="features">产品特点</el-checkbox>
        <el-checkbox class="check" label="features_en"
          >产品特点(EN)</el-checkbox
        >
        <el-checkbox class="check" label="usage">使用方法</el-checkbox>
        <el-checkbox class="check" label="usage_en">使用方法(EN)</el-checkbox>
        <el-checkbox class="check" label="notes">注意事项</el-checkbox>
        <el-checkbox class="check" label="notes_en">注意事项(EN)</el-checkbox>
        <el-checkbox class="check" label="caution">警告语</el-checkbox>
        <el-checkbox class="check" label="caution_en">警告语(EN)</el-checkbox>
        <el-checkbox class="check" label="standard">执行标准</el-checkbox>
        <el-checkbox class="check" label="expiration">保质期</el-checkbox>
        <el-checkbox class="check" label="credential">产品相关证书</el-checkbox>
        <el-checkbox class="check" label="model">产品型号</el-checkbox>
        <el-checkbox class="check" label="packaging">包装形式</el-checkbox>
        <el-checkbox class="check" label="hs_code">海关编码</el-checkbox>
        <el-checkbox class="check" label="purchase_spec">规格</el-checkbox>
        <el-checkbox class="check" label="ban_area">禁配地区</el-checkbox>
        <el-checkbox class="check" label="xm_batteryinfo">电池信息</el-checkbox>
        <el-checkbox class="check" label="xm_logo">包装LOGO</el-checkbox>
        <el-checkbox class="check" label="xm_lxys">流行元素</el-checkbox>
      </el-checkbox-group>

      <p class="clp">配送价(大于或等于)：</p>
      <el-input v-model="price"></el-input>

      <p class="clp">产品环境：</p>
      <el-checkbox-group v-model="goodsFor">
        <el-checkbox label="0">国外</el-checkbox>
        <el-checkbox label="1">国内</el-checkbox>
      </el-checkbox-group>

      <p class="clp">
        商品条码：
      </p>
      <el-input type="textarea" :rows="10" v-model="goodsItems" />

      <p class="clp">
        <el-button @click="exportGoods" type="primary">导出</el-button>
      </p>
    </div>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span
        >数据正在加载导出，请耐心等待....<i class="el-icon-loading"></i
      ></span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >已下载</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checkList: [],
      price: 0,
      goodsFor: [],
      dialogVisible: false,
      goodsItems:''
    };
  },
  methods: {
    handleClose(done) {
      done();
    },
    exportGoods() {

      if (this.checkList.length < 1) {
        this.$message.success("请选择要导出的数据字段");
        return;
      }
      let fieldArr = [];
      for (let i = 0; i < this.checkList.length; i++) {
        fieldArr.push(this.checkList[i]);
      }
      let inLang = [];
      for (let j = 0; j < this.goodsFor.length; j++) {
        inLang.push(this.goodsFor[j]);
      }
      this.dialogVisible = true;
      let postData = {
        in_lang: inLang,
        field: fieldArr,
        price: parseFloat(this.price),
        goods_items:this.goodsItems.split("\n").join(",")
      };
      this.$axios.post("/a1/goods/creatExportGoods", postData,{timeout: 10 * 60 * 1000}).then((res) => {
        let dowloadKey = res.data.data.to_key;
        //开始下载
        location.href = this.$root.DownUrl + "/c1/down/" + dowloadKey;
      });
    },
  },
};
</script>

<style scoped>
.exportTitle {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 10px;
}

.clp {
  margin: 20px 0;
}

h3 {
  border-bottom: 1px solid sandybrown;
  padding: 0 0 20px 0;
}

.check {
  width: 120px;
}
</style>
